const ZAPIER_DRAFT_HOOKS = {
  '0054I000005pxw5QAA': 'https://hooks.zapier.com/hooks/catch/6483660/osbb1q5/', // Sagiv
  '0054I000005pzYZQAY': 'https://hooks.zapier.com/hooks/catch/6483660/osb3vet/', // Bar
  '00558000000TMzCAAW': 'https://hooks.zapier.com/hooks/catch/6483660/osb2tr2/', // Eden
  '0054I000005Sq1wQAC': 'https://hooks.zapier.com/hooks/catch/6483660/osb23cx/', // Revital
  '00558000001ssUjAAI': 'https://hooks.zapier.com/hooks/catch/6483660/osqbxzj/', // Lia
  '00558000002sBdHAAU': 'https://hooks.zapier.com/hooks/catch/6483660/ocqed0x/', // Tamar
  '00558000000xcemAAA': 'https://hooks.zapier.com/hooks/catch/2976135/ovkaqlt/', // Tamuz
  '0054I000007Jbm9QAC': 'https://hooks.zapier.com/hooks/catch/6483660/byu3lby/', // Efrat
  '0054I000007bcxnQAA': 'https://hooks.zapier.com/hooks/catch/6483660/bihkebu/', // Hila
  '0054I000007OtrRQAS': 'https://hooks.zapier.com/hooks/catch/6483660/bgmul1e/', // Liza
}

export function create_draft(userId, { emailTo, htmlBody, subject }) {
  const hook_url = ZAPIER_DRAFT_HOOKS[userId]
  if (!hook_url) {
    throw new Error('No Zapier hook for prepping draft for user')
  }
  return fetch(hook_url, { method: 'POST', body: JSON.stringify({ htmlBody, emailTo, subject }) })
}
