import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'

import { query, sf_link } from '../salesforce'

const Intro = ({ Id, Name, Status__c, Connector__r, CreatedDate, Last_Status_Update__c, LastActivityDate, Goal__c }) => {
  const connector = _.get(Connector__r, 'Name', false)
  const connector_txt = connector ? ` (via ${connector}) ` : ''
  const last_activity = LastActivityDate || Last_Status_Update__c || CreatedDate
  return (<>
    <a href={sf_link('Introduction__c', Id)}>{Name}</a>{connector_txt} [{Goal__c} - {Status__c}] (last updated: {last_activity.slice(0, 10)})
  </>)
}

export const ExpandedSalesAlert = ({ sales_alert, conn }) => {
  const [loading, set_loading] = useState(false)
  const [intros_for_this_alert, set_intros_for_this_alert] = useState([])
  const [intros_for_this_account, set_intros_for_this_account] = useState([])
  const [intros_for_this_contact, set_intros_for_this_contact] = useState([])
  const [intros_for_this_contact_different_account, set_intros_for_this_contact_different_account] = useState([])
    
  const alert_id = sales_alert.Id
  const account_id = sales_alert.account_id
  const contact_id = sales_alert.Contact__c

  useEffect(() => {
    const read_alert_intros = async () => {
      const Q = `SELECT Id, Name, Status__c, Connector__r.Name, CreatedDate, Last_Status_Update__c, LastActivityDate, Goal__c FROM Introduction__c WHERE Sales_Alert__c = '${alert_id}'`
      const res = await query(conn, Q)
      set_intros_for_this_alert(res.records)
    }
    const read_account_intros = async () => {
      const Q = `SELECT Id, Name, Status__c, Connector__r.Name, CreatedDate, Last_Status_Update__c, LastActivityDate, Goal__c, Target_Account__c FROM Introduction__c WHERE Target_Account__c = '${account_id}' OR To_Who__c = '${contact_id}'`
      const res = await query(conn, Q)
      set_intros_for_this_account(res.records.filter( ({ Target_Account__c }) => (Target_Account__c === account_id) ))
      set_intros_for_this_contact_different_account(res.records.filter( ({ Target_Account__c }) => (Target_Account__c !== account_id) ))
    }
    const read_connector_intros = async () => {
      const Q = `SELECT Id, Name, Status__c, Connector__r.Name, CreatedDate, Last_Status_Update__c, LastActivityDate, Goal__c FROM Introduction__c WHERE Connector__c = '${contact_id}'`
      const res = await query(conn, Q)
      set_intros_for_this_contact(res.records)
    }
        
    const read_data = async () => {
      set_loading(true)
      try {
        await Promise.all([read_alert_intros(), read_account_intros(), read_connector_intros()])
      } finally {
        set_loading(false)
      }
    }
        
    read_data()
  }, [alert_id, account_id, contact_id, conn])

  const intros_for_alert_ids = new Set(intros_for_this_alert.map(({ Id }) => Id))
  return (
    <p style={{ margin: 0 }}>
      {loading ? (
        <Spin />
      ) : (
        <>
          <h3>Intros for This Sales Alert</h3>
          <ul>
            {intros_for_this_alert.map( (intro) => (
              <li><Intro key={intro.Id} {...intro} /></li>
            ))}
          </ul>
          <h3>Other Intros for This Account</h3>
          <ul>
            {intros_for_this_account.filter(({ Id }) => !intros_for_alert_ids.has(Id)).map( (intro) => (
              <li><Intro key={intro.Id} {...intro} /></li>
            ))}
          </ul>
          <h3>Intros Where They are the Connector</h3>
          <ul>
            {intros_for_this_contact.map( (intro) => (
              <li><Intro key={intro.Id} {...intro} /></li>
            ))}
          </ul>
          <h3>Intros Where They are the Target (for a different account)</h3>
          <ul>
            {intros_for_this_contact_different_account.filter(({ Id }) => !intros_for_alert_ids.has(Id)).map( (intro) => (
              <li><Intro key={intro.Id} {...intro} /></li>
            ))}
          </ul>
        </>
      )}
    </p>
  )
}
