import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
  Tag,
  Button,
  Input,
  Menu,
  Dropdown,
  Icon,
  PageHeader,
} from 'antd'

import { Table } from 'ant-table-extensions'

import _ from 'lodash'

import moment from 'moment'

import { LinkedinFilled } from '@ant-design/icons'
import { query, sf_link } from '../salesforce'
import { MODES, SuggestionModal } from './suggest_modal'
import { ExpandedSalesAlert } from './alert-view'

export const SalesforceIcon = (props) => (
  <img height="15px" src={"./salesforce.svg"} alt="salesforce" {...props} />
)

const TYPES = ['New Role', 'Relationship Update'] // , 'Upcoming Meeting']
const STATUSES = [
  'New',
  'Deep Review',
  'Dismissed',
  'Suggested to Portco',
  'Saved for Later',
  'Checking Internally',
  // 'Accepted by Portco',
  // 'Rejected by Portco',
]

const STATUSES_TO_DISPLAY = [
  'New',
]

const RELATIONSHIP_CATEGORY = {
  '0': 'No Relationship',
  '1': 'Slim Chance',
  '2': 'Need to Check',
  '3': 'Good Chance',
  '4': 'Friend'
}

function normalize_sf_id(id) { // fluff up a 15 char id to return an 18 char id
  if (id.length !== 15) {
    return id
  }
  var suffix = ''
  for (var i = 0; i < 3; i++) {
    var flags = 0
    for (var j = 0; j < 5; j++) {
      var c = id.charAt(i * 5 + j)
      if (c >= 'A' && c <= 'Z') {
        flags += 1 << j
      }
    }
    if (flags <= 25) {
      suffix += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.charAt(flags)
    } else {
      suffix += '012345'.charAt(flags-26)
    }
  }
  return id + suffix
}

function translate_slack_links(txt) {
  const link_detection_re = /(<.*\|.*>)/
  const link_parse_re = /<(.*)\|(.*)>/
  const parts = txt.split(link_detection_re)
  return parts.map(p => {
    const match = p.match(link_parse_re)
    if (match) {
      const [, url, txt] = match
      return <a href={url}>{txt}</a>
    } else {
      return <span>{p}</span>
    }
  })
}

function remove_slack_links(txt) {
  const link_detection_re = /(<.*\|.*>)/
  const link_parse_re = /<(.*)\|(.*)>/
  const parts = txt.split(link_detection_re)
  return parts.map(p => {
    const match = p.match(link_parse_re)
    if (match) {
      const [, , txt] = match
      return txt
    } else {
      return p
    }
  })
}

const process_record = ({ Id, CreatedDate, Name, Contact__c, Contact__r, Contact_Role__r, Type__c, Reasons__c, Status__c, Relevant_Portcos__c }) => ({
  Id,
  datetime: moment(CreatedDate),
  alert_id: { text: Name, id: Id },
  name: { text: Contact__r.Name, sf: sf_link('Contact', Contact__c), li: Contact__r.Linkedin_Profile__c },
  owner: { text: Contact__r.Owner.Name },
  country: Contact__r.MailingCountry,
  city: Contact__r.MailingCity,
  account: { text: _.get(Contact_Role__r, 'Account_Name__c', 'NO CR?'), sf: sf_link('Account', _.get(Contact_Role__r, 'Account__c')) },
  type: Type__c,
  relationship: { num: Contact__r.Relationship_Category__c, category: `${Contact__r.Relationship_Category__c} - ${RELATIONSHIP_CATEGORY[Contact__r.Relationship_Category__c]}`, details: (Contact__r.Relationship_Category_Components__c || '').split('\n') },
  reasons: Reasons__c.split('\n'),
  status: Status__c,
  role_summary: _.get(Contact_Role__r, 'Role_Summary__c'),
  Contact__c,
  account_id: _.get(Contact_Role__r, 'Account__c'),
  portcos: (Relevant_Portcos__c || '').split('\n').map(x => x.trim()).map(normalize_sf_id)
})

const DismissButton = ({ alert_id, conn, on_dismiss }) => {
  const [loading, set_loading] = useState(false)
  const [reason, set_reason] = useState('')
  const dismiss = async () => {
    set_loading(true)
    try {
      await conn.sobject('Sales_Alert__c').update({ 'Id': alert_id, 'Status__c': 'Dismissed', 'Reject_Reason__c': reason })
      on_dismiss(alert_id)
    } finally {
      set_loading(false)
    }
  }
  const on_change = (e) => set_reason(e.target.value)
  return (
    <p className="actions">
      <Button loading={loading} onClick={dismiss}>Dismiss</Button>
      <Input placeholder="Reason [optional]" value={reason} onChange={on_change}/>
    </p>
  )
}

const ActionMenu = ({ alert_id, alert, conn, on_dismiss, on_action, portcos }) => {
  const [suggest_modal_open, set_suggest_modal_open] = useState(false)
  const [suggest_modal_mode, set_suggest_modal_mode] = useState(null)
  const [loading, set_loading] = useState(false)
  const close_suggest_modal = () => set_suggest_modal_open(false)
    
  const handlers = {
    suggest_to_portco: () => {
      set_suggest_modal_mode(MODES.IMMEDIATE)
      set_suggest_modal_open(true)
    },
    check_internally: () => {
      set_suggest_modal_mode(MODES.CHECK_INTERNALLY)
      set_suggest_modal_open(true)
    },
    save_for_later: () => {
      set_suggest_modal_mode(MODES.SAVE_FOR_LATER)
      set_suggest_modal_open(true)
    },
    deep_review: async () => {
      set_loading(true)
      try {
        await conn.sobject('Sales_Alert__c').update({ 'Id': alert_id, 'Status__c': 'Deep Review' })
        on_action()
      } finally {
        set_loading(false)
      }
    },

  }
  const on_menu_click = ( { key } ) => handlers[key]()
  const action_menu = (
    <Menu onClick={on_menu_click} disabled={loading}>
      <Menu.Item key="suggest_to_portco">Suggest to Portco</Menu.Item>
      <Menu.Item key="check_internally">Check Internally</Menu.Item>
      <Menu.Item key="save_for_later">Save for Later</Menu.Item>
      <Menu.Item key="deep_review">Mark for Deep Review</Menu.Item>
    </Menu>
  )
    

  return (
    <>
      {(suggest_modal_open) && (
        <SuggestionModal
          visible={suggest_modal_open}
          close={close_suggest_modal}
          on_success={on_action}
          alerts={[alert]}
          conn={conn}
          mode={suggest_modal_mode}
          portcos={portcos}
        />
      )}
      <DismissButton alert_id={alert_id} conn={conn} on_dismiss={on_dismiss} />
      <Dropdown overlay={action_menu}>
        <Button loading={loading}>
                    Actions <Icon type="down" />
        </Button>
      </Dropdown>
    </>
  )
}

export const SalesAlertsTable = ({ conn }) => {
  const [portcos, set_portcos] = useState([])
  const [alerts, set_alerts] = useState([])
  const [loading, set_loading] = useState(false)
  const [selected_keys, set_selected_keys] = useState([])
  const [suggest_modal_open, set_suggest_modal_open] = useState(false)
  const open_suggest_modal = () => set_suggest_modal_open(true)
  const close_suggest_modal = () => set_suggest_modal_open(false)
  const relevant_portcos = useMemo(() => {
    const ids = new Set(_.flatten(alerts.map(a => a.portcos)))
    return portcos.filter(({ Id }) => ids.has(Id))
  }, [portcos, alerts])
    
    
  useEffect(() => {
    const fetch_portcos = async () => {
      const Q = 'SELECT Id, Name FROM Account WHERE Type=\'Portfolio\' ORDER BY Name ASC'
      const res = await query(conn, Q)
      set_portcos(res.records)
    }
    fetch_portcos()
  }, [conn])
   
  const read_alerts = useCallback (
    async () => {
      set_loading(true)
      const statuses_string = STATUSES.map(s => `'${s}'`).join(', ')
      const types_string = TYPES.map(s => `'${s}'`).join(', ')
      const ALERT_FIELDS = `
                        Id,
                        CreatedDate,
                        Name,
                        Type__c,
                        Status__c,
                        Reasons__c,
                        Contact__c,
                        Relevant_Portcos__c,
                        Contact__r.Name,
                        Contact__r.Owner.Name,
                        Contact__r.MailingCity,
                        Contact__r.MailingCountry,
                        Contact__r.Relationship_Category__c,
                        Contact__r.Relationship_Category_Components__c,
                        Contact__r.Linkedin_Profile__c,
                        Contact_Role__r.Name,
                        Contact_Role__r.Account_Name__c,
                        Contact_Role__r.Account__c,
                        Contact_Role__r.Role_Summary__c
                    `
      const ALERT_CONDS = `
                        Status__c IN (${statuses_string}) AND 
                        Type__c IN (${types_string})
                `
      const INTROS_WHERE_TARGET_FIELDS = 'To_Who__c, Id'
      const INTROS_WHERE_TARGET_COND = `
To_Who__c IN (
  SELECT Contact__c FROM Sales_Alert__c WHERE Status__c IN (${statuses_string})
) AND (CreatedDate = LAST_N_DAYS:90) AND Goal__c IN ('Sales Opportunity', 'Business Development')
`
      const INTROS_WHERE_CONNECTOR_FIELDS = 'Connector__c, Id'
      const INTROS_WHERE_CONNECTOR_COND = `
Connector__c IN (
  SELECT Contact__c FROM Sales_Alert__c WHERE Status__c IN (${statuses_string})
) AND (CreatedDate = LAST_N_DAYS:90) AND Goal__c IN ('Sales Opportunity', 'Business Development')
`
      const alerts_res = await conn.sobject('Sales_Alert__c').select(ALERT_FIELDS).where(ALERT_CONDS).run({ autoFetch: true, maxFetch: 100000 })
      const [intro_where_target_res, intro_where_connector_res] = await Promise.all([
        conn.sobject('Introduction__c').select(INTROS_WHERE_TARGET_FIELDS).where(INTROS_WHERE_TARGET_COND).run({ autoFetch: true, maxFetch: 100000 }),
        conn.sobject('Introduction__c').select(INTROS_WHERE_CONNECTOR_FIELDS).where(INTROS_WHERE_CONNECTOR_COND).run({ autoFetch: true, maxFetch: 100000 }),
      ])
      const records = alerts_res.map(process_record)
      const has_intro = {}
      intro_where_connector_res.forEach(({ Connector__c }) => {
        has_intro[Connector__c] = true
      })
      intro_where_target_res.forEach(({ To_Who__c }) => {
        has_intro[To_Who__c] = true
      })
      records.forEach((record) => {
        record.has_intro = has_intro[record.Contact__c]
      })
      set_alerts(records)
      set_loading(false)
    },
    [conn]
  )

  const on_intro_success = () => {
    set_selected_keys([])
    read_alerts()
  }
    
  useEffect(() => {
    read_alerts()
  }, [read_alerts])

  const owners = useMemo(() => Array.from(new Set( alerts.map(({ owner }) => owner.text))), [alerts])
  const countries = useMemo(() => Array.from(new Set( alerts.map(({ country }) => country))).filter( x=> !_.isEmpty(x)), [alerts])
  const cities = useMemo(() => Array.from(new Set( alerts.map(({ city }) => city))).filter( x=> !_.isEmpty(x)), [alerts])

  const on_dismiss = (alert_id) => {
    set_alerts(alerts.filter(a => a.Id !== alert_id))
  }


  const rowSelection = {
    selectedRowKeys: selected_keys,
    onChange: set_selected_keys,
  }

  const columns = [
    {
      title: 'Alert',
      dataIndex: 'alert_id',
      key: 'alert_id',
      render: ({ text, id }) => (<span className="alertid"><a href={`https://aleph.lightning.force.com/lightning/r/Sales_Alert__c/${id}/view`}>{text}</a></span>),
      sorter: (a, b) => parseInt(a.alert_id.text.split('-')[1], 10) - parseInt(b.alert_id.text.split('-')[1], 10),
      defaultSortOrder: 'descend',
      width: 100,
      csv_render: ({ text, id }) => text,
    },
    {
      title: 'Recent Intro',
      dataIndex: 'has_intro',
      key: 'has_intro',
      render: (has_intro) => has_intro ? 'yes' : 'no',
      filters: [{ text: 'yes', value: true }, { text: 'no', value: false }],
      onFilter: (value, record) => Boolean(record.has_intro) === value,
      defaultFilteredValue: [false],
      width: 80,
      csv_render: (has_intro) => has_intro ? 'yes' : 'no',
    },
    {
      title: 'Date',
      dataIndex: 'datetime',
      key: 'datetime',
      render: (date) => date.calendar(),
      csv_render: (date) => date.calendar(),
      sorter: (a, b) => a.datetime.diff(b.datetime),
      width: 95,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      filters: TYPES.map(s => ({ text: s, value: s })),
      onFilter: (value, record) => record.type === value,
      width: 100,
      csv_render: x => x,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: STATUSES.map(s => ({ text: s, value: s })),
      onFilter: (value, record) => record.status === value,
      defaultFilteredValue: STATUSES_TO_DISPLAY,
      width: 70,
      csv_render: x => x,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: ( ({ text, sf, li }) => <span className="name">{text}{' '}<br /><a href={sf}><SalesforceIcon /></a>{' '}<a href={li}><LinkedinFilled /></a></span> ),
      csv_render: ({ text, sf, li }) => text,
      width: 100,
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      render: ( ({ text, sf }) => <span className="account"><a href={sf}>{text}</a></span> ),
      csv_render: ({ text, sf }) => text,
      width: 100,
    },
    {
      title: 'Relationship',
      dataIndex: 'relationship',
      key: 'relationship',
      render: ({ category, details }) => (<p className="relationship"><b>{category}</b><br/><ul>{details.map(l => (<li key={l}>{l}</li>))}</ul></p>),
      csv_render: ({ category, details }) => (`${category} - ${details.join(' | ')}`),
      filters: _.keys(RELATIONSHIP_CATEGORY).map(s => ({ text: `${s} - ${RELATIONSHIP_CATEGORY[s]}`, value: s })),
      onFilter: (value, record) => record.relationship.num === value,
      width: 150,
    },
    {
      title: 'Reasons',
      dataIndex: 'reasons',
      key: 'reasons',
      render: (reasons) => (<p className="reasons"><ul>{reasons.map(r => (<li key={r}>{translate_slack_links(r)}</li>))}</ul></p>),
      csv_render: (reasons) => (reasons.map(r => remove_slack_links(r).join(''))),
      width: 200,
    },
    {
      title: 'Portcos',
      dataIndex: 'portcos',
      key: 'portcos',
      render: (alert_portcos) => (<p className="reasons"><ul>{relevant_portcos.filter(({ Id }) => alert_portcos.includes(Id)).map(({ Name }) => (<li key={Name}>{Name}</li>))}</ul></p>),
      csv_render: (alert_portcos) => relevant_portcos.filter(({ Id }) => alert_portcos.includes(Id)).map(({ Name }) => (Name)).join(', '),
      width: 100,
      filters: relevant_portcos.map(( { Id, Name } ) => ({ text: Name, value: Id })),
      onFilter: (value, record) => record.portcos.includes(value),
    },        
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      filters: owners.map(o => ({ text: o, value: o })),
      render: ( ({ text }) => <span className="owner">{text}</span> ),
      csv_render: ( ({ text }) => text),
      onFilter: (value, record) => record.owner.text === value,
      width: 100,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      filters: countries.map(o => ({ text: o, value: o })),
      onFilter: (value, record) => record.country === value,
      csv_render: x => x,
      width: 100,
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      filters: cities.map(o => ({ text: o, value: o })),
      onFilter: (value, record) => record.city === value,
      csv_render: x => x,
      width: 100,
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'Id',
      render: (id, alert) => (
        <ActionMenu alert_id={id} alert={alert} conn={conn} on_dismiss={on_dismiss} on_action={read_alerts} portcos={portcos} />
      ),
      fixed: 'right',
      width: 200,
    }
  ]
  
  const export_fields = _.fromPairs(
    columns
      .filter(({ csv_render }) => _.isFunction(csv_render))
      .map( ({ title, key, csv_render }) => ([key, { header: title, formatter: csv_render }]) )
  )

  const hasSelected = selected_keys.length > 0
  const new_alerts = alerts.filter(({ status }) => status === 'New').length
  return (
    <>
      <PageHeader
        ghost={false}
        title="Sales Alerts"
        subTitle={`${new_alerts} new alerts found`}
        tags={hasSelected ? <Tag> {selected_keys.length} Selected </Tag> : undefined}
        extra={[
          <Button type="primary" onClick={hasSelected ? open_suggest_modal : undefined }>
                        Suggest to Portco
          </Button>
        ]}
      >
      </PageHeader>
      <Table
        rowKey='Id'
        className="screener"
        size="middle"
        columns={columns}
        dataSource={alerts}
        loading={loading}
        rowSelection={rowSelection}
        pagination={{ 
          position: 'both', 
          defaultPageSize: 50, showSizeChanger: true, pageSizeOptions: ['10', '20', '50', '100']
        }}
        expandedRowRender={sales_alert => <ExpandedSalesAlert sales_alert={sales_alert} conn={conn} />}
        scroll={{ x: 1300 }}
        exportable
        exportableProps={{ fields: export_fields }}
      />
      {(suggest_modal_open) && (
        <SuggestionModal
          visible={suggest_modal_open}
          close={close_suggest_modal}
          on_success={on_intro_success}
          alerts={alerts.filter(({ Id }) => selected_keys.includes(Id))}
          conn={conn}
          mode={MODES.IMMEDIATE}
          portcos={portcos}
        />
      )}
    </>
  )
}
