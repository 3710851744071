import React, { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'

import { Sankey } from '@ant-design/plots'
import { Select, DatePicker } from 'antd'
import moment from 'moment'

import { query, useConnection } from '../salesforce'

const { Option } = Select
const { MonthPicker, RangePicker, WeekPicker } = DatePicker


const group_count = (lst, key) => {
  const grps = _.groupBy(lst, key)
  return _(_.toPairs(grps)).sortBy([([k, v])=>-v.length]).map( ([k, v]) => `${k}: ${v.length}` ).join(', ')
}

const group_count_with_pct = (lst, key) => {
  const grps = _.groupBy(lst, key)
  const s = lst.length
  return _(_.toPairs(grps)).sortBy([([k, v])=>-v.length]).map( ([k, v]) => `${k}: ${v.length} (${((v.length / s)*100).toFixed(0)}%)` ).join(', ')
}

const group_avg = (lst, group_key, avg_key) => {
  const grps = _.groupBy(lst, group_key)
  return _.toPairs(grps).map( ([k, v]) => `${k}: ${_.mean(v.map(t => _.get(t, avg_key))).toFixed(2)}` ).join(', ')
}

const intro_ref = (id) => `https://aleph.lightning.force.com/lightning/r/Introduction__c/${id}/view`

const STATUS_TO_INTRO_STATE = {
  'To review': 'pending',
  'Sent Email to Facilitator': 'pending',
  'Facilitator Agreed': 'pending',
  'Saved for Later': 'pending',
  'Checking Internally': 'pending',
  'Checking Internally - Pinged': 'pending',
  'Awaiting Info from Portfolio': 'pending',
  'Can\'t Reach Out': 'failed',
  'Deferred': 'failed',
  'Asked': 'pending',
  'Asked Connector - Pinged': 'pending',
  'Didn\'t Respond': 'failed',
  'Declined_by_Connector': 'failed',
  'Agreed': 'pending',
  'Connector Agreed - Pinged': 'pending',
  'Sent': 'pending',
  'Request Sent to Target - Pinged': 'pending',
  'Target Company is Checking': 'pending',
  'No Response': 'failed',
  'Declined by Target': 'failed',
  'Introduced': 'success',
  'Feedback Requested': 'success',
  'Feedback Provided': 'success',
  'No Feedback': 'success',
  'No relationship with connector': 'failed',
  'Weak relationship with connector': 'failed',
  'Declined by Facilitator': 'failed',
  'On Hold': 'pending',
  'Declined by Requestor': 'failed',
}

const SALESPOD_INTRO_GOALS = ['Sales Opportunity', 'Business Development', 'Accelerator/growth program', 'Finance & Operations']
const SALESPOD_INTRO_GOALS_LIST = SALESPOD_INTRO_GOALS.map(g => `'${g}'`).join(', ')

const EXPECTED_IMPACT = {
  '0. Inactive': 'Inactive',
  '1. Nice to Have': 'Nice to Have',
  '2. Significant Value': 'Focus for Partner',
  '3. Saves the Company': 'Reviewed with Partnership',
}

const EDEN_USER_ID = '00558000000TMzCAAW'
const TOMER_USER_ID = '0054I000007JDyuQAG'
const MICHAEL_USER_ID = '00558000000TMzMAAW'
const YAEL_USER_ID = '00558000000TJoNAAW'

export const PartnerSelect = ({ on_select }) => {
  const choices = useMemo( () => ({
    'Eden': EDEN_USER_ID,
    'Tomer': TOMER_USER_ID,
    'Michael': MICHAEL_USER_ID,
    'Yael': YAEL_USER_ID,
  }), [])
  const default_choice = _.keys(choices)[0]
  const [choice, set_choice] = useState(default_choice)
  useEffect(() => {
    on_select(choices[choice])
  }, [choice, on_select, choices])

  return (
    <div>
      <Select defaultValue={default_choice} onChange={set_choice} style={{ width: 200 }}>
        {_.keys(choices).map((k) => (
          <Option value={k} key={k} selected={k===choice}>{k}</Option>
        ))}
      </Select>
    </div>
  )
}

export const DateRangeSelect = ({ on_select, biweekly, date_field }) => {
  const date_field_to_use = date_field || 'CreatedDate'
  const biweekly_choices = useMemo(() => biweekly ? {
    'Past Two Weeks': `${date_field_to_use}>=LAST_N_DAYS:14`
  } : {}, [biweekly, date_field_to_use])
  const choices = useMemo( () => ({
    ...biweekly_choices,
    'This Q': `${date_field_to_use}=THIS_QUARTER`,
    'Prev Q': `${date_field_to_use}=LAST_QUARTER`,
    'This Week': `${date_field_to_use}>= THIS_WEEK`,
    'This Month': `${date_field_to_use}>= THIS_MONTH`,
    'This Year': `${date_field_to_use}>= THIS_YEAR`,
    'Prev Year': `${date_field_to_use}= LAST_YEAR`,
    'Choose Week': '',
    'Choose Month': '',
    'Custom Range': '',
  }), [biweekly_choices, date_field_to_use])
  const default_choice = _.keys(choices)[0]
  const [choice, set_choice] = useState(default_choice)
  useEffect(() => {
    if (!_.isEmpty(choices[choice])) {
      on_select(choices[choice])
    }
  }, [choice, on_select, choices])
  const on_pick_range = (_, [start, end]) => {
    const Q = `CreatedDate >= ${start}T00:00:00Z AND CreatedDate <= ${end}T23:59:59Z`
    on_select(Q)
  }
  const on_pick_week = (m) => {
    const start = m.startOf('week').format()
    const end = m.endOf('week').format()
    const Q = `CreatedDate >= ${start} AND CreatedDate <= ${end}`
    on_select(Q)
  }
  const on_pick_month = (m) => {
    const start = m.startOf('month').format()
    const end = m.endOf('month').format()
    const Q = `CreatedDate >= ${start} AND CreatedDate <= ${end}`
    on_select(Q)
  }

  return (
    <div>
      <Select defaultValue={default_choice} onChange={set_choice} style={{ width: 200 }}>
        {_.keys(choices).map((k) => (
          <Option value={k} key={k} selected={k===choice}>{k}</Option>
        ))}
      </Select>
      {(choice === 'Custom Range') && (
        <RangePicker onChange={on_pick_range} />
      )}
      {(choice === 'Choose Week') && (
        <WeekPicker onChange={on_pick_week} format="YYYY-MM-DD" />
      )}
      {(choice === 'Choose Month') && (
        <MonthPicker onChange={on_pick_month} />
      )}
    </div>
  )
}


const useFieldLabels = () => {
  const conn = useConnection()
  const [field_labels, set_field_labels] = useState({})
  useEffect( () => {
    if (!conn) { return }
    const get_fields = async () => {
      const desc = await conn.sobject('Introduction__c').describe()
      const [status_field] = desc.fields.filter(f=>f.name === 'Status__c')
      const labels = _.fromPairs(status_field.picklistValues.map(v => [v.value, v.label]))
      const unmatched_fields = _.keys(labels).filter(x => _.isUndefined(STATUS_TO_INTRO_STATE[x]))
      if (!_.isEmpty(unmatched_fields)) {
        throw new Error(`these fields are not mapped: ${unmatched_fields}`)
      }
      set_field_labels(labels)
    }
    get_fields()
  }, [conn])
  return field_labels  
}

const process_intro = (intro, field_labels) => {
  intro.intro_state = STATUS_TO_INTRO_STATE[intro.Status__c]
  intro.status_label = field_labels[intro.Status__c]
  intro.Source__c = intro.Source__c || 'Other'
  intro.Sales_Status__c = intro.Sales_Status__c || 'No Sales Process'
  intro.Most_Advanced_Sales_Status__c = intro.Most_Advanced_Sales_Status__c || 'No Sale'

  return intro
}

export const SalespodDashboard = () => {
  const conn = useConnection()
  const [intros, set_intros] = useState([])
  const [nailed_valgens, set_nailed_valgens] = useState([])
  const [finished_valgens, set_finished_valgens] = useState([])
  const [open_valgens, set_open_valgens] = useState([])
  const [created_valgens, set_created_valgens] = useState([])
  const [capacity_histories, set_capacity_histories] = useState([])
  const [sla_histories, set_sla_histories] = useState([])
  const [date_query, set_date_query] = useState('')
  const field_labels = useFieldLabels()

  useEffect( () => {
    const get_intros = async () => {
      if (!date_query) {
        return
      }
      const introsQ = `SELECT 
                Id, Name, Status__c, Feedback__c, Sales_Status__c, Most_Advanced_Sales_Status__c, Source__c, Goal__c, Portfolio_Account_Name__c, LastModifiedDate, When__c, Target_Account__c
                FROM Introduction__c 
                WHERE 
                Goal__c IN (${SALESPOD_INTRO_GOALS_LIST})
                AND ${date_query}
            `
      const intros_res = await query(conn, introsQ)
      set_intros(intros_res.records.map(i => process_intro(i, field_labels)))
      
      const last_modified_date_query = date_query.replace(/CreatedDate/g, 'LastModifiedDate')
      const salespod_valgen_condition = 'Value_Provided_By__r.Name IN (\'Hila Tsaphrir\', \'Sagiv Malihi\')'
      const nailed_valgensQ = `SELECT 
                Id, Name, Value_Provided_By__r.Name, Source__c, Expected_Impact__c, Outcome__c, Account__r.Name
                FROM Impact__c 
                WHERE 
                Outcome__c = 'We nailed it'
                AND ${salespod_valgen_condition}
                AND ${date_query}
            `
      const nailed_valgens_res = await query(conn, nailed_valgensQ)
      set_nailed_valgens(nailed_valgens_res.records)

      const finished_valgensQ = `SELECT 
                Id, Name, Value_Provided_By__r.Name, Source__c, Expected_Impact__c, Outcome__c, Account__r.Name
                FROM Impact__c 
                WHERE 
                Outcome__c NOT IN ('Waiting', 'In Process', '') 
                AND ${salespod_valgen_condition}
                AND ${last_modified_date_query}
            `
      const finished_valgens_res = await query(conn, finished_valgensQ)
      set_finished_valgens(finished_valgens_res.records)

      const created_valgensQ = `SELECT 
                Id, Name, Value_Provided_By__r.Name, Source__c, Expected_Impact__c, Outcome__c, Account__r.Name
                FROM Impact__c 
                WHERE 
                ${salespod_valgen_condition} AND ${date_query}
            `
      const created_valgen_res = await query(conn, created_valgensQ)
      set_created_valgens(created_valgen_res.records)
      
      const open_valgensQ = `SELECT 
                Id, Name, Value_Provided_By__r.Name, Source__c, Expected_Impact__c, Outcome__c, Account__r.Name
                FROM Impact__c 
                WHERE 
                Outcome__c IN ('Waiting', 'In Process', '')
                AND Expected_Impact__c <> '0. Inactive'
                AND ${salespod_valgen_condition}
            `
      const open_valgens_res = await query(conn, open_valgensQ)
      set_open_valgens(open_valgens_res.records)      
      
      const HistoriesQ = `
                SELECT
                    ParentId,Field,OldValue,NewValue,CreatedById,CreatedBy.Name,Parent.Name
                FROM Introduction__History
                WHERE 
                    Parent.Goal__c IN (${SALESPOD_INTRO_GOALS_LIST})
                    AND Field IN ('Status__c', 'created')
                    AND ${date_query}
            `
      const histories_res = await query(conn, HistoriesQ)
      set_capacity_histories(histories_res.records)

      const parent_date_query = date_query.replace(/CreatedDate/g, 'Parent.CreatedDate')
      const HistoriesSLAQ = `
                SELECT
                    ParentId,Field,OldValue,NewValue,CreatedBy.Name,CreatedById,CreatedDate,Parent.Portfolio_Account_Name__c,Parent.Source__c,Parent.CreatedDate,Parent.Name
                FROM Introduction__History
                WHERE 
                    Parent.Goal__c IN (${SALESPOD_INTRO_GOALS_LIST})
                    AND Parent.Source__c = 'Aleph Connect'
                    AND Field IN ('Status__c', 'created')
                    AND ${parent_date_query}
            `
      const histories_sla_res = await query(conn, HistoriesSLAQ)
      set_sla_histories(histories_sla_res.records)
    }
    get_intros()
  }, [conn, field_labels, date_query])
  const bizdev_intros = intros.filter(x => x.Goal__c === 'Business Development')
  const sales_intros = intros.filter(x => x.Goal__c === 'Sales Opportunity')
  const waste_of_time_bd = bizdev_intros.filter(x => (x.Feedback__c === 'Irrelevant') || (x.Feedback__c === 'Not interesting value'))
  const relevant_bizdev = bizdev_intros.filter(x => (x.Feedback__c === 'Relevant and Valuable' || x.Feedback__c === 'Immediate Action Items'))
  const pending = intros.filter(x => x.intro_state === 'pending')
  const failed = intros.filter(x => x.intro_state === 'failed')
  const success = intros.filter(x => x.intro_state === 'success')
  const accounts_attempted = Array.from(new Set(intros.map(i => i.Target_Account__c)))
  const accounts_introduced = Array.from(new Set(success.map(i => i.Target_Account__c)))
  const status_known = success.filter(x => (x.Sales_Status__c !== 'No Sales Process') && (!(x.Sales_Status__c === 'In Play' && (x.Most_Advanced_Sales_Status__c === 'Initial' || x.Most_Advanced_Sales_Status__c === 'First Meeting' || x.Most_Advanced_Sales_Status__c === 'No Sale'))))
  const second_meeting = success.filter(x => 
    (x.Sales_Status__c === 'Closed Won') ||
        (x.Most_Advanced_Sales_Status__c === 'Second Meeting') ||
        (x.Most_Advanced_Sales_Status__c === 'POC') ||
        (x.Most_Advanced_Sales_Status__c === 'Closed Won'))
  const second_meeting_durations = second_meeting.filter(i => !_.isEmpty(i.When__c)).map(i => (moment(i.LastModifiedDate) - moment(i.When__c)) / (1000*3600*24))
  const waste_of_time_sales = sales_intros.filter(x => x.Sales_Status__c === 'Dead' && x.Most_Advanced_Sales_Status__c === 'First Meeting')
  const ghosting_sales = sales_intros.filter(x => x.Sales_Status__c === 'Dead' && x.Most_Advanced_Sales_Status__c === 'Initial')
  const portco_intros = intros.filter(x => !_.isEmpty(x.Portfolio_Account_Name__c))
  const active_statuses = new Set([
    'On Hold',
    'Awaiting Info from Portfolio',
    'Checking Internally',
    'Checking Internally - Pinged',
    'Sent Email to Facilitator',
    'Can\'t Reach Out',
    'Deferred',
    'Asked',
    'Asked Connector - Pinged',
    'Sent',
    'Agreed',
    'Connector Agreed - Pinged',
    'Target Company is Checking',
    'Request Sent to Target - Pinged',
    'Intorduced',
    'Feedback Requested'
  ])

  const active_capacity_histories = capacity_histories.filter( ({ Field, NewValue }) => (Field === 'created') || ((Field === 'Status__c') && ( active_statuses.has(NewValue) )))
  const intros_handled = _.uniqBy(capacity_histories.filter(({ CreatedById, Field }) => (Field !== 'created') ), 'ParentId')
  const intros_actively_handled = _.uniqBy(active_capacity_histories, 'ParentId')

  const time_to_handle = {}
  sla_histories.filter( ({ Field }) => Field==='created').forEach( (change) => {
    time_to_handle[change.ParentId] = { sla: (moment.now() - moment(change.CreatedDate)) / (1000 * 3600), ...change }
  })
  sla_histories.filter( ({ Field, OldValue }) => ((Field === 'Status__c') && (OldValue === 'To review'))).forEach( (change) => {
    time_to_handle[change.ParentId].sla = (moment(change.CreatedDate) - moment(time_to_handle[change.ParentId].CreatedDate)) / (1000*3600)
  })

  const sla_list = _.values(time_to_handle)

  const sankeyData = []
  const keys = ['Source__c', 'intro_state', 'status_label', 'Sales_Status__c', 'Most_Advanced_Sales_Status__c']
  const hist = {}
  intros.forEach((d) => {
    const key = keys.map(k => `${d[k]}`).join('-')
    if (_.isUndefined(hist[key])) {
      hist[key] = _.fromPairs(keys.map( k => [k, d[k]] ))
      hist[key].value = 1
    } else {
      hist[key].value += 1
    }
  })
  _.values(hist).forEach((d) => {
    keys.reduce((a, b) => {
      if (a && b) {
        sankeyData.push({
          source: d[a],
          target: d[b],
          value: d.value,
          path: `${d[keys[0]]} -> ${d[keys[1]]} -> ${d[keys[2]]} -> ${d[keys[3]]}`,
        })
      }
      return b
    })
  })
  const config = {
    data: sankeyData,
    sourceField: 'source',
    targetField: 'target',
    weightField: 'value',
    nodeWidthRatio: 0.01,
    nodePaddingRatio: 0.03,
    nodeDraggable: true,
    rawFields: ['path'],
    tooltip: {
      fields: ['path', 'value'],
      formatter: ({ path, value }) => {
        return {
          name: path,
          value: value,
        }
      },
    },
  }
  return (
    <div>
      <DateRangeSelect on_select={set_date_query} />
      <h1>Summary</h1>
      <div>
            KR Status:
        <ul>
          <li>Intros Created: {intros.length} ({group_count(intros, 'Goal__c')})</li>
          <li>Introduced: {success.length} / 60 ({group_count(success, 'Goal__c')})</li>
          <li>2nd Meeting: {second_meeting.length} / 20 ({group_count(second_meeting, 'Goal__c')})</li>
          <li>Qualified Leads Generated: XXX / 10000</li>
          <li>{'\'Nailed It\' Valgens:'} {nailed_valgens.length} / 4</li>
          <li>{'\'Relevant & Valuable\' BD Intros:'} {relevant_bizdev.length} </li>
        </ul>
        Anti-KRs:
        <ul>
          <li>{'Sales - Dead on Initial (ghosting?):'} {ghosting_sales.length} </li>
          <li>{'Sales - Dead on First Meeting:'} {waste_of_time_sales.length} </li>
          <li>{'BD - Not Valuable:'} {waste_of_time_bd.length} </li>
        </ul>
      </div>
      <div>Participating portcos: {group_count(portco_intros, 'Portfolio_Account_Name__c')} </div>
      <h1>KR Details</h1>
      <div>SLA (time to first response, hrs): {group_avg(sla_list, 'Parent.Portfolio_Account_Name__c', 'sla')}</div>
      <div>Intros modified: {intros_handled.length} ({group_count(intros_handled, 'CreatedBy.Name')})</div>
      <div>Total Intros Created: {intros.length} ({group_count(intros, 'Goal__c')})</div>
      <div>Target Accounts: {accounts_attempted.length}</div>
      <div>Target Accounts Successfully Introduced: {accounts_introduced.length}</div>
      <div>By Source: {group_count(intros, 'Source__c')}</div>
      <div>Pending Intros: {pending.length} ({group_count(pending, 'status_label')})</div>
      <div>Failed Intros: {failed.length} ({group_count(failed, 'status_label')})</div>
      <div>Successful Intros: {success.length} ({group_count(success, 'status_label')})</div>
      <div>Successful Intros By Source: {group_count_with_pct(success, 'Source__c')}</div>
      <div>Outcome Known: {status_known.length} ({group_count(status_known, 'Most_Advanced_Sales_Status__c')}) ({group_count(status_known, 'Sales_Status__c')})</div>
      <div>Second Meeting+ Intros: {second_meeting.length} [Most Advanced Status: ( {group_count(second_meeting, 'Most_Advanced_Sales_Status__c')} ), [Sales Status: ( {group_count(second_meeting, 'Sales_Status__c')} )]</div>
      <div>Avg Time to Second Meeting+ Intros: {_.mean(second_meeting_durations).toFixed(0)} days</div>
      <div>Request-to-Intro rate: {success.length} / ({intros.length} - {pending.length}) = {((success.length / (intros.length - pending.length))*100).toFixed(2)}%</div>
      <div>Intro to 2nd Meeting rate: {second_meeting.length} / {status_known.length} = {(100*(second_meeting.length / status_known.length)).toFixed(2)}%</div>
      <h1>Intros</h1>
      <div><b>Created in this timeframe ({intros.length} intros):</b></div>
      <IntroList intros={_.sortBy(intros, ['Portfolio_Account_Name__c', 'Status__c'])} />
      <div><b>Handled in this timeframe:</b></div>
      {intros_actively_handled.map( (i) => (<li key={i.ParentId}>{i.Parent.Name}</li>))}
      <h1>Valgens (projects)</h1>
      <div><b>Created in this timeframe:</b></div>
      {created_valgens.map( ({ Name, Account__r, Outcome__c, Expected_Impact__c }) => (<li key={Name}>{Account__r.Name} - {Name} ({EXPECTED_IMPACT[Expected_Impact__c]}) - {Outcome__c} </li>))}
      <div><b>Done in this timeframe:</b></div>
      {finished_valgens.map( ({ Name, Account__r, Outcome__c, Expected_Impact__c }) => (<li key={Name}>{Account__r.Name} - {Name} ({EXPECTED_IMPACT[Expected_Impact__c]}) - {Outcome__c} </li>))}
      <div><b>Still Open:</b></div>
      {open_valgens.map( ({ Name, Account__r, Outcome__c, Expected_Impact__c }) => (<li key={Name}>{Account__r.Name} - {Name} ({EXPECTED_IMPACT[Expected_Impact__c]}) - {Outcome__c} </li>))}
      <Sankey {...config} />
    </div>
  )
}

const IntroBrief = ({ i }) => {
  if (i.intro_state === 'success') {
    return (<li><div><a href={intro_ref(i.Id)}>{i.Name}</a> - {i.status_label} ({i.Goal__c})</div><div style={{ fontSize: '0.9em' }}>Feedback: {i.Feedback__c} ({i.Feedback_Details__c})</div><div style={{ fontSize: '0.9em' }}>Sales Status: {i.Sales_Status__c}, Most Advanced Sales Stage: {i.Most_Advanced_Sales_Status__c}</div></li>)
  } else {
    return (<li><a href={intro_ref(i.Id)}>{i.Name}</a> - {i.status_label} ({i.Goal__c})</li>)
  }
}
const IntroList = ({ intros }) => (
  <div>
    <ul>
      {intros.map( (i) => <IntroBrief key={i.Id} i={i} /> )}
    </ul>
  </div>
)
const IntroStats = ({ intros }) => {
  return (
    <span>
      {intros.length} total intros, {intros.filter(i => i.intro_state === 'success').length} introduced, {intros.filter(i => i.intro_state === 'failed').length} failed to introduce ({intros.filter(i => i.intro_state === 'pending').length} still in progress)
    </span>
  )
}


export const PartnerReport = () => {
  const field_labels = useFieldLabels()
  const [partner, set_partner] = useState(EDEN_USER_ID)
  const conn = useConnection()
  const [intros, set_intros] = useState([]) 
  const [new_intros, set_new_intros] = useState([])
  const [this_q_intros, set_this_q_intros] = useState([])

  useEffect( () => {
    const get_intros = async () => {
      const modified_Q = `SELECT 
                Id, Name, Status__c, Sales_Status__c, Most_Advanced_Sales_Status__c, Source__c, Goal__c, Portfolio_Account_Name__c,
                Feedback__c,  Feedback_Details__c
                FROM Introduction__c 
                WHERE 
                Goal__c IN (${SALESPOD_INTRO_GOALS_LIST})
                AND Account__r.OwnerId='${partner}'
                AND LastModifiedDate>=LAST_N_DAYS:14
            `
      const modified_res = await query(conn, modified_Q)
      set_intros(modified_res.records.map(i => process_intro(i, field_labels)))
      
      const created_Q = `SELECT 
                Id, Name, Status__c, Sales_Status__c, Most_Advanced_Sales_Status__c, Source__c, Goal__c, Portfolio_Account_Name__c
                FROM Introduction__c 
                WHERE 
                Goal__c IN (${SALESPOD_INTRO_GOALS_LIST})
                AND Account__r.OwnerId='${partner}'
                AND CreatedDate>=LAST_N_DAYS:14
                AND Status__c = 'To review' 
            `
      const created_res = await query(conn, created_Q)
      set_new_intros(created_res.records.map(i => process_intro(i, field_labels)))
    
      const created_this_QQ = `
                SELECT 
                Id, Name, Status__c, Sales_Status__c, Most_Advanced_Sales_Status__c, Source__c, Goal__c, Portfolio_Account_Name__c,
                Generated_Value__c, Generated_Value__r.Name, Generated_Value__r.Outcome__c
                FROM Introduction__c
                WHERE 
                Goal__c IN (${SALESPOD_INTRO_GOALS_LIST})
                AND Account__r.OwnerId='${partner}'
                AND CreatedDate=LAST_N_MONTHS:3
      `
      const created_this_q_res = await query(conn, created_this_QQ)
      set_this_q_intros(created_this_q_res.records.map(i => process_intro(i, field_labels)))
    }
    get_intros()
  }, [conn, field_labels, partner])
  
  const introduced = intros.filter(i => i.intro_state === 'success')
  const failed = intros.filter(i => i.intro_state === 'failed')
  const pending = intros.filter(i => i.intro_state === 'pending')
  const valgens = _.fromPairs(this_q_intros.filter(i => i.Generated_Value__r)
    .filter(i => _.isEmpty(i.Generated_Value__r.Outcome__c)) 
    .map(i => [i.Generated_Value__c, [i.Generated_Value__r.Name, i.Portfolio_Account_Name__c]]))
  const non_valgen_intros = this_q_intros
    .filter(i => _.isEmpty(i.Generated_Value__r) || !_.isEmpty(i.Generated_Value__r.Outcome__c))
    .filter(i => !_.isEmpty(i.Portfolio_Account_Name__c))
  const portcos = Array.from(new Set(non_valgen_intros.map(i => i.Portfolio_Account_Name__c)))


  // <DateRangeSelect on_select={set_date_query} biweekly={true} date_field={'LastModifiedDate'} />
  return (
    <div>
      <PartnerSelect on_select={set_partner} />
      <h1>Val Gens From This Quarter</h1>
      {_.toPairs(valgens).map( ([vid, [vname, aname]]) => (
        <div>
          <strong><span>{aname}: </span><a href={`https://aleph.lightning.force.com/lightning/r/Impact__c/${vid}/view`}>{vname}</a> - </strong>
          <IntroStats intros={this_q_intros.filter( (i) => i.Generated_Value__r && (i.Generated_Value__c === vid))} />
        </div>
      ))}
      {portcos.map( (pname) => (
        <div>
          <span><strong>{pname}: </strong></span>
          <IntroStats intros={non_valgen_intros.filter(i => i.Portfolio_Account_Name__c === pname)} />
        </div>
      ))}
      <h1>News Section (Intros Updated in Past 2 Weeks)</h1>
      <h2>Successful Intros</h2>
      <IntroList intros={introduced} />
      <h2>Intros in Play</h2>
      <IntroList intros={pending} />
      <h2>Failed Intros</h2>
      <IntroList intros={failed} />
      <h1>Intros Submitted</h1>
      <IntroList intros={new_intros} />
    </div>
  )
}
