import React from 'react'
import { Modal, Button } from 'antd'

const SalesforceIcon = (props) => (
  <img height="28px" src={'./salesforce.svg'} alt="salesforce" {...props} />
)

export function Login({ onClick }) {
  return (
    <div>
      <Modal
        className="login-modal"
        centered
        closable={false}
        visible={true}
        footer={[
          <Button key="submit" type="primary" loading={false} ghost onClick={onClick} style={{ fontSize: '20px', height: '44px', lineHeight: '44px' }}>
            <SalesforceIcon style={{ marginRight: '1rem' }}/>{'  '} Login With Salesforce
          </Button>,
        ]}
      >
        <p>Please login to continue</p>
      </Modal>
    </div>
  )
}
