import { useState, useEffect, useContext, createContext } from 'react'
import { getSfConfig } from './config'
import jsforce from 'jsforce'
import _ from 'lodash'

console.log('init jsforce', getSfConfig())
jsforce.browser.init(getSfConfig())

export function useLogin() {
  const [logged_in, set_logged_in] = useState(jsforce.browser.isLoggedIn())
  const [connection, set_connection] = useState(null)
  const login = () => jsforce.browser.login()
  useEffect( () => {
    const on_connect = (conn) => {
      console.log('setting connection!', conn)
      set_logged_in(true)
      set_connection(conn)
    }
    jsforce.browser.on('connect', _.once(on_connect))
  }, [])

  return [logged_in, login, connection]
}

export const ConnectionContext = createContext(null)

export function useConnection() {
  const connection = useContext(ConnectionContext)
  return connection
}

export const query = (conn, ...args) => conn.query(...args).run({ autoFetch : true, maxFetch : 10000 })

export const sf_link = (type, id) => `https://aleph.lightning.force.com/lightning/r/${type}/${id}/view`


