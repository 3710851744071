export const PROD_APP_CLIENT_ID = '3MVG98_Psg5cppyawYB96o0JMRhDafCxhgbcvH4S29YxwlVeraYGcpU0ia5uAklPqfb5_ZAXS78Igrl52jWDs'
export const PROD_APP_REDIRECT_URL = 'https://salesalerts.aleph.vc/'
export const TESTING_APP_CLIENT_ID = '3MVG98_Psg5cppyawYB96o0JMRj1Cczhlo_rmIuu4.dWl7b8RaIjj8LzM.tNFET0MSFoYWevnxBAvHVFibduu'
export const TESTING_APP_REDIRECT_URL = 'http://localhost:3000/'

function isProd(){
  return window.location.hostname ==='salesalerts.aleph.vc'
}

export function getSfConfig(){
  const prodConfig = {
    clientId: PROD_APP_CLIENT_ID,
    redirectUri: PROD_APP_REDIRECT_URL
  }
  const devConfig = {
    clientId: TESTING_APP_CLIENT_ID,
    redirectUri: TESTING_APP_REDIRECT_URL
  }
  
  return isProd() ? prodConfig : devConfig
}
