import _ from 'lodash'

import React, { useState, useEffect } from 'react'

import {
  Modal,
  notification,
  message,
  Select,
  Form,
  DatePicker,
  Input,
} from 'antd'

import { query, sf_link } from '../salesforce'
import { create_draft } from '../gmail'
import { create_intro_object, create_reminder } from '../intro'

const { Option, OptGroup } = Select

const EMAIL_TEMPLATE = ({ Name, AccountName, alerts }) => `
<p>Hi ${Name},</p>

<p>We identified a few contacts who joined our network or moved to new positions, and could be potentially relevant for ${AccountName} as customers. Note we haven't checked interest with any of them, and in order to reach out, we might need customized texts from you.</p>

<p>
The contacts are:
<ol>
${alerts.map(({ name, role_summary }) => `<li><a href="${name.li}">${name.text}</a> - ${role_summary}</li>`).join('\n')}
</ol>
</p>

<p>Please let us know which of the above are qualified prospects for a reach out, and how should we proceed.</p>
`

export const MODES = {
  IMMEDIATE: 'immediate',
  SAVE_FOR_LATER: 'save for later',
  CHECK_INTERNALLY: 'check internally',
}

export const SuggestionModal = ({ conn, visible, close, on_success, alerts, mode, portcos }) => {
  const relevant_portcos = new Set(_.flatten(alerts.map(a => a.portcos)))
  const [selected_portco] = (relevant_portcos.size === 1) ? relevant_portcos : [null]
    
  const [portco, set_portco] = useState(selected_portco)
  const [contacts, set_contacts] = useState([])
  const [contact, set_contact] = useState(null)
  const [reminder_date, set_reminder_date] = useState('')
  const [reason, set_reason] = useState('')

  const on_reason_change = (e) => {
    set_reason(e.target.value)
  }
  const on_reminder_date_change = (date, dateString) => {
    set_reminder_date(dateString)
  }

  useEffect(() => {
    const fetch_contacts = async () => {
      const Q = `SELECT Id, Name, FirstName, Email FROM Contact WHERE AccountId = '${portco}' AND Email != null`
      const res = await query(conn, Q)
      set_contacts(res.records)
    }
    if (portco) {
      fetch_contacts()
    }
  }, [portco, conn])
    
  const update_status_to_suggested = async (ids) => {
    const statuses_by_mode = {
      [MODES.IMMEDIATE]: 'Suggested to Portco',
      [MODES.SAVE_FOR_LATER]: 'Saved for Later',
      [MODES.CHECK_INTERNALLY]: 'Checking Internally',
    }
    const status = statuses_by_mode[mode]
    const updates = ids.map((id) => ({ Id: id, Status__c: status, Reject_Reason__c: reason }))
    await conn.sobject('Sales_Alert__c').update(updates)
  }

  const create_intro = async () => {
    try {
      const [c] = contacts.filter(({ Id }) => Id === contact)
      const [a] = portcos.filter(({ Id }) => Id === portco)
      const intro_status_by_mode = {
        [MODES.IMMEDIATE]: 'Awaiting Info from Portfolio',
        [MODES.SAVE_FOR_LATER]: 'On Hold',
        [MODES.CHECK_INTERNALLY]: 'Checking Internally',
      }
      const intro_status = intro_status_by_mode[mode]
      const intros = await Promise.all(alerts.map(sales_alert => create_intro_object(conn, a, c, sales_alert, intro_status)))
      if (mode === MODES.IMMEDIATE) {
        const email = EMAIL_TEMPLATE({ Name: c.FirstName, AccountName: a.Name, alerts })
        await create_draft(conn.userInfo.id, { emailTo: c.Email, htmlBody: email, subject: `Potential prospects for ${a.Name} from Aleph Sales Alerts` })
      }
      if ((mode === MODES.SAVE_FOR_LATER) && (!_.isEmpty(reminder_date))) {
        await Promise.all(intros.map((intro) => create_reminder(conn, intro.id, reminder_date)))
      }
      await update_status_to_suggested(alerts.map(({ Id }) => Id))
      const message_by_mode = {
        [MODES.IMMEDIATE]: 'Intros + draft created successfully',
        [MODES.SAVE_FOR_LATER]: 'Intro created as \'On Hold\'',
        [MODES.CHECK_INTERNALLY]: 'Intro created as \'Checking Internally\'',
      }
      notification.success({
        message: message_by_mode[mode],
        duration: 0,
        description:
                  'Click here to open intro in new tab',
        onClick: () => {
          intros.map((intro) => window.open(sf_link('Introduction__c', intro.id)))
        },
      })
      on_success()
    } catch (e) {
      message.error(`Oops something wrong happened: ${e.message} ${e}`)
    } finally {
      close()
    }
  }
  const ok_text_by_mode = {
    [MODES.IMMEDIATE]: 'Create Intro(s)+Draft(s)',
    [MODES.SAVE_FOR_LATER]: 'Create \'On Hold\' Intro',
    [MODES.CHECK_INTERNALLY]: 'Create \'Checking Internally\' Intro',
  }
  const title_by_mode = {
    [MODES.IMMEDIATE]: 'Suggest Opportunity to Portco',
    [MODES.SAVE_FOR_LATER]: 'Save for Later',
    [MODES.CHECK_INTERNALLY]: 'Check Internally',
  }
  return (
    <Modal
      visible={visible}
      onCancel={close}
      onOk={create_intro}
      title={title_by_mode[mode]}
      okText={ok_text_by_mode[mode]}
    >
      <Form onSubmit={create_intro}>
        <Form.Item>
          <Select
            showSearch
            placeholder="Select a portco"
            optionFilterProp="children"
            onChange={set_portco}
            defaultValue={_.isNull(portco) ? undefined : portco}
            filterOption={(input, option) =>
              _.some(
                _.isArray(option.props.children) ? option.props.children.map(x=>x.props.children) : [option.props.children],
                x => x.toLowerCase().indexOf(input.toLowerCase()) >= 0
              )
            }
          >
            {(relevant_portcos.size > 0) && (
              <OptGroup label="Suggested">
                {portcos.filter(({ Id }) => relevant_portcos.has(Id)).map(({ Id, Name }) => (
                  <Option key={Id} value={Id}>{Name}</Option>
                ))}
              </OptGroup>
            )}
            {portcos.filter(({ Id }) => !relevant_portcos.has(Id)).map(({ Id, Name }) => (
              <Option key={Id} value={Id}>{Name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Select
            showSearch
            placeholder="Select a person"
            optionFilterProp="children"
            onChange={set_contact}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {contacts.map(({ Id, Name }) => (
              <Option key={Id} value={Id}>{Name}</Option>
            ))}
          </Select>
        </Form.Item>
        {(mode === MODES.SAVE_FOR_LATER) && (
          <Form.Item label="Remind me at... (optional)">
            <DatePicker onChange={on_reminder_date_change} />
          </Form.Item>
        )}
        <Form.Item label="Why?">
          <Input placeholder="document your decisions so we can later automate" value={reason} onChange={on_reason_change} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
