import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import { Layout, Menu, Avatar } from 'antd'
const { Content, Header } = Layout

export default function Container({ menu_items, children }) {
  const [item, set_item] = useState(null)
  const on_select = ({ key }) => {
    set_item(menu_items[key])
  }
  useEffect( () => {
    if (!_.isEmpty(menu_items)) {
      set_item(menu_items[_.keys(menu_items)[0]])
    }
  }, [menu_items])
  return (
    <div className="App">
      <Layout>
        <Header>
          <div className="logo">
            <Avatar src="./logo192.png" />
          </div>
          <Menu
            mode="horizontal"
            defaultSelectedKeys={_.isEmpty(menu_items) ? [] : [_.keys(menu_items)[0]]}
            style={{ lineHeight: '64px' }}
            onSelect={on_select}
          >
            {_.keys(menu_items).map(k =>
              <Menu.Item key={k}>{k}</Menu.Item>
            )}
          </Menu>
        </Header>
        <Content style={{ padding: '1rem 1rem 1rem 1rem' }}>
          {item}
          {children}
        </Content>
      </Layout>
    </div>
  )
}
