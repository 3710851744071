import 'antd/dist/antd.css'
import './App.css'

import _ from 'lodash'

import { useLogin, ConnectionContext } from './salesforce'
import { Login } from './components/login'
import { SalesAlertsTable } from './components/sales-alerts-table'
import { SalespodDashboard } from './components/salespod-dashboard'
import { PartnerReport } from './components/salespod-dashboard'
import Container from './components/container'

function App() {
  const [logged_in, login, conn] = useLogin()
  console.log('app.js', { logged_in, conn })
  return (logged_in && !_.isNull(conn)) ? (
    <ConnectionContext.Provider value={conn}>
      <Container
        className="App"
        menu_items=
          {
            {
              'Dashboard': <SalespodDashboard />,
              'Sales Alerts': <SalesAlertsTable conn={conn} />,
              'Partner Report': <PartnerReport />,
            }
          } 
      />
    </ConnectionContext.Provider>
  ) : (
    <Container>
      <Login onClick={login}/>
    </Container>
  )
}

export default App
