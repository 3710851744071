import _ from 'lodash'

export const create_intro_object = async (conn, account, contact, sales_alert, intro_status) => {
  const { Id, Contact__c, account_id } = sales_alert
  const intro = {
    OwnerId: conn.userInfo.id,
    Requestor__c: (_.isEmpty(contact) ? undefined : contact.Id),
    To_Who__c: Contact__c,
    Target_Account__c: account_id,
    Goal__c: 'Sales Opportunity',
    Status__c: intro_status,
    Feedback__c: null,
    Account__c: account.Id,
    Source__c: 'Sales Alert',
    Sales_Alert__c: Id,
  }
  const created = await conn.sobject('Introduction__c').insert(intro)
  console.log('created intro', created)
  return created
}

export const create_reminder = async (conn, intro_id, date) => {
  const task = {
    OwnerId: conn.userInfo.id,
    Subject: 'review saved for later intro',
    ActivityDate: date,
    WhatId: intro_id,
  }
  const created = await conn.sobject('Task').insert(task)
  console.log('created reminder task', created)
  return created
}
